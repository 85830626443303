// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publication-card[data-v-13e1a883] {
  border: var(--13e1a883-\\\$getStyles\\.borderSize) solid var(--13e1a883-\\\$getStyles\\.borderColor);
  border-radius: var(--13e1a883-\\\$getStyles\\.borderRadius);
}
.publication-details[data-v-13e1a883] {
  padding-top: var(--13e1a883-\\\$getStyles\\.paddingTop);
  padding-left: var(--13e1a883-\\\$getStyles\\.paddingLeft);
  padding-right: var(--13e1a883-\\\$getStyles\\.paddingRight);
  padding-bottom: var(--13e1a883-\\\$getStyles\\.paddingBottom);
}
.description-wrapper[data-v-13e1a883] {
  padding-top: var(--13e1a883-\\\$getStyles\\.paddingTopSmall);
  padding-bottom: var(--13e1a883-\\\$getStyles\\.paddingBottomSmall);
}
.subheading[data-v-13e1a883] {
  line-height: unset;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/smart-feed/publication/layouts/PublicationCardSmall.vue","webpack://./PublicationCardSmall.vue"],"names":[],"mappings":"AAEA;EACE,8FAAA;EACA,wDAAA;ACDF;ADIA;EACE,oDAAA;EACA,sDAAA;EACA,wDAAA;EACA,0DAAA;ACDF;ADIA;EACE,yDAAA;EACA,+DAAA;ACDF;ADIA;EACE,kBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.publication-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.publication-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}\n\n.description-wrapper {\n  padding-top: v-bind(\"$getStyles.paddingTopSmall\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottomSmall\");\n}\n\n.subheading {\n  line-height: unset;\n}\n",".publication-card {\n  border: v-bind(\"$getStyles.borderSize\") solid v-bind(\"$getStyles.borderColor\");\n  border-radius: v-bind(\"$getStyles.borderRadius\");\n}\n\n.publication-details {\n  padding-top: v-bind(\"$getStyles.paddingTop\");\n  padding-left: v-bind(\"$getStyles.paddingLeft\");\n  padding-right: v-bind(\"$getStyles.paddingRight\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottom\");\n}\n\n.description-wrapper {\n  padding-top: v-bind(\"$getStyles.paddingTopSmall\");\n  padding-bottom: v-bind(\"$getStyles.paddingBottomSmall\");\n}\n\n.subheading {\n  line-height: unset;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

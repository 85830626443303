<template>
  <iStyledCard
    :splash-image="entity.publicationSplashImage"
    :badge="entity.status"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/publication/${entity.publicationId}`"
    :metrics="entity.metrics"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iColumn width="hug">
          <iImageContainer
            object-fit="cover"
            :height="35"
            :width="35"
            shape="circle"
            :src="entity.publicationLogo"
          />
        </iColumn>
        <iColumn gap="none">
          <iSubHeading class="subheading" font-weight="dark">
            {{ entity.name }}
          </iSubHeading>
          <iLink :href="subTitleHref">
            <iText font-size="small">
              {{ entity.productionVersionId ? "View production site" : "View staging site" }}
            </iText>
          </iLink>
        </iColumn>
      </iRow>
    </template>
    <template v-if="entity.description" #description>
      <iColumn class="description-wrapper">
        <iLink
          v-if="!entity.hasError"
          hover-underline
          target="_blank"
          :href="descriptionHref"
        >
          {{ entity.description }}
        </iLink>
        <iText v-else variant="error">
          {{ entity.description }}
        </iText>
      </iColumn>
    </template>
  </iStyledCard>
</template>

<script>
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";

export default {
  name: "PublicationCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showViewDropdown: false,
      DEFAULT_ORG_LOGO,
      DEFAULT_SPLASH_IMAGE,
    };
  },
  computed: {
    subTitleHref() {
      if (this.entity.productionVersionId) {
        return `${window.location.origin}/my-publication/${this.entity.publicationId}`;
      }
      return `${window.location.origin}/my-publication/staging/${this.entity.publicationId}`;
    },
    descriptionHref() {
      return `${window.location.origin}/my-publication/staging/${this.entity.publicationId}`;
    },
  },
  methods: {
    snakeToTitle,
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
    paddingTopSmall: { "size.spacing": "extraSmall" },
    paddingLeftSmall: { "size.spacing": "extraSmall" },
    paddingRightSmall: { "size.spacing": "extraSmall" },
    paddingBottomSmall: { "size.spacing": "extraSmall" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}

.description-wrapper {
  padding-top: v-bind("$getStyles.paddingTopSmall");
  padding-bottom: v-bind("$getStyles.paddingBottomSmall");
}

.subheading {
  line-height: unset;
}
</style>

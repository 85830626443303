<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_BRANDING"
    title="Branding"
    :breadcrumbs="breadcrumbs"
    page-name="Branding"
    align="left"
    width="full"
  >
    <iColumn gap="large">
      <iTextEdit
        :width="500"
        max-width-only
        :value="publicationName"
        label="Name"
        placeholder="Give your publication a name"
        :is-loading="isLoadingSavePublicationName"
        @submit="savePublicationName"
      />
      <iTextEdit
        :width="500"
        max-width-only
        :value="publicationDescription"
        label="Description"
        placeholder="Give your publication a description"
        :is-loading="isLoadingSavePublicationDescription"
        text-area
        :text-area-rows="3"
        @submit="savePublicationDescription"
      />
      <iImageUploader
        :image="publicationImageSrc"
        change-image-text="Update your logo"
        select-image-text="Upload your logo"
        button-variant="secondary"
        @upload-image="uploadPublicationLogoImage"
      />
      <iImageUploader
        :image="splashImageSrc"
        change-image-text="Update splash image"
        select-image-text="Upload splash image"
        button-variant="secondary"
        @upload-image="uploadPublicationSplashImage"
      />
      <iRow>
        <iButton :is-loading="loadingUploadPublicationLogo" :disabled="canSave" @click.stop="submit">
          Save
        </iButton>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>

import { mapActions, mapState } from "pinia";
import PageWrapper from "@/components/PageWrapper";
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { usePublicationStore } from "@/stores/publication-store";
import {
  PAGE_PUBLICATION_BRANDING,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";

export default {
  name: "PublicationBranding",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_BRANDING,
      SECTION_PUBLICATIONS,
      uploadedPublicationLogo: null,
      uploadedSplashImage: null,
      themeId: null,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
      "publicationId",
      "loadingUploadPublicationLogo",
      "publicationLogo",
      "publicationSplashImage",
      "publication",
      "publicationName",
      "publicationDescription",
      "isLoadingSavePublicationName",
      "isLoadingSavePublicationDescription",
    ]),
    canSave() {
      let disabled = true;
      if (this.uploadedPublicationLogo) {
        disabled = false;
      }
      if (this.uploadedSplashImage) {
        disabled = false;
      }
      return disabled;
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    publicationImageSrc() {
      if (!this.publicationLogo) {
        return DEFAULT_ORG_LOGO;
      } else {
        return this.publicationLogo;
      }
    },
    splashImageSrc() {
      if (!this.publicationSplashImage) {
        return DEFAULT_SPLASH_IMAGE;
      } else {
        return this.publicationSplashImage;
      }
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["uploadPublicationLogo", "uploadSplashImage", "savePublicationName", "savePublicationDescription"]),
    uploadPublicationLogoImage(image) {
      this.uploadPublicationLogo(image);
    },
    uploadPublicationSplashImage(image) {
      this.uploadSplashImage(image);
    },
    submit() {
      if (this.uploadedPublicationLogo) {
        this.uploadPublicationLogo(this.uploadedPublicationLogo).then(() => {
          this.uploadedPublicationLogo = null;
        });
      }
      if (this.uploadedSplashImage) {
        this.uploadSplashImage(this.uploadedSplashImage).then(() => {
          this.uploadedSplashImage = null;
        });
      }
    },
  },
};
</script>

<style scoped>
:deep(.i-select-dropdown){
  bottom:0px;
}
</style>

<template>
  <PageWrapper :active-section="SECTION_PUBLICATIONS" :active-page="PAGE_PUBLICATION_POSTS">
    <iColumn v-if="postViewType === 'edit'" height="hug">
      <iRow vertical-align="middle">
        <iCheckbox
          v-model="exclusive"
          :is-loading="updatePostExclusivityIsLoading"
          :disabled="!canMarkContentAsExclusive"
          label="Exclusive"
          @change="updatePostExclusivityFlag"
        />
        <iColumn v-tooltip="exclusiveCheckboxTooltip" height="hug" width="hug">
          <iIcon icon="info-circle" />
        </iColumn>
      </iRow>
    </iColumn>
    <iRichTextEditor
      ref="editor"
      :preview-mode="postViewType === 'view'"
      hashtag-value-key="hashtag"
      :validation="validateHashtag"
      submit-button-text="Update"
      :upload-post-image="uploadFunction"
      :update-post-is-loading="updatePostIsLoading"
      enable-hashtags
      cancel-button-text="Back to Posts"
      @input:content="saveWithDebounce"
      @cancel:post="cancelPost"
      @delete:hashtag="removeHashtag"
      @submit:hashtag="addHashtag"
    >
      <template #submit-button>
        <iRow vertical-align="middle">
          <iText variant="subtle">
            Last Updated: {{ postLastUpdated }}
          </iText>
          <iLoadingSpinner v-if="updatePostIsLoading" />
        </iRow>
      </template>
    </iRichTextEditor>
    <iModal
      v-if="showEditPostConfirmation"
      title="Edit Post"
      :width="600"
      :close-on-backdrop="false"
      :close-on-escape="false"
      :close-on-secondary="false"
      :primary-action-button-loading="updatePostIsLoading"
      primary-action-button-label="Continue"
      @close="closeEditPostConfirmation"
      @click:primary="goToEditPost"
      @click:secondary="closeEditPostConfirmation"
    >
      <template #body>
        <iParagraph>
          Your post status will be changed to Draft/Unpublished. Are you sure you want to edit this post?
        </iParagraph>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { PAGE_PUBLICATION_POSTS, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper.vue";
import { postStatus } from "@/enums/post-enums";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default {
  name: "ViewPublicationPost",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_POSTS,
      SECTION_PUBLICATIONS,
      exclusive: false,
      showEditPostConfirmation: false,
      debounce: null,
      debounceEnabled: false,
      postLastUpdated: null,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publicationId",
      "postViewType",
      "uploadPostImageLoading",
      "loadingAddHashtagToPost",
      "errorAddHashtagToPost",
      "canMarkContentAsExclusive",
      "updatePostExclusivityIsLoading",
    ]),
    ...mapWritableState(usePublicationStore, ["post", "updatePostIsLoading"]),
    exclusiveCheckboxTooltip() {
      if (this.canMarkContentAsExclusive) {
        return "You can mark this content as exclusive. This will make it only visible to active subscribers.";
      }
      return "This is only available if you have login or subscriptions enabled for your publication.";
    },
    hashtags: {
      get() {
        return this.post.hashtags;
      },
      set(value) {
        this.post.hashtags = value;
      },
    },
    timestampUpdated() {
      return this.post.timestamp_updated;
    },
  },
  watch: {
    timestampUpdated() {
      this.postLastUpdated = this.timeSince(this.post.timestamp_updated);
    },
  },
  mounted() {
    this.$refs.editor.title = this.post.post_title;
    this.$refs.editor.editor.commands.setContent(this.post.html);
    this.$refs.editor.hashtags = this.hashtags;
    this.exclusive = !!this.post.exclusive;
    if (this.post.status === postStatus.ACTIVE) {
      this.showEditPostConfirmation = true;
    }
    this.postLastUpdated = this.timeSince(this.post.timestamp_updated);
    setInterval(() => {
      this.postLastUpdated = this.timeSince(this.post.timestamp_updated);
    }, 60000);
  },
  methods: {
    ...mapActions(usePublicationStore, [
      "uploadPostImage",
      "updatePublicationPost",
      "addHashtagsToPost",
      "removeHashtagFromPost",
      "updatePostExclusiveContentFlag",
    ]),
    timeSince(date) {
      return dayjs.unix(date).fromNow();
    },
    closeEditPostConfirmation() {
      window.location.href = `/publication/${this.publicationId}/posts`;
    },
    goToEditPost() {
      this.submitPost(this.post.post_title, this.post.html, postStatus.DRAFT, () => {
        this.showEditPostConfirmation = false;
      }, true);
    },
    saveWithDebounce({ title, content }) {
      if (this.debounceEnabled) {
        this.updatePostIsLoading = true;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.submitPost(title, content);
      }, 1000);
    },
    addHashtag(newTag) {
      this.addHashtagsToPost([newTag], () => {
        this.$refs.editor.hashtags.pop();
      });
    },
    removeHashtag({ el, index }) {
      if (this.postViewType === "edit") {
        this.removeHashtagFromPost(el.hashtag_id, () => {
          this.$refs.editor.hashtags.splice(index, 0, el);
        });
      }
    },
    validateHashtag(value) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(value);
      if (!result) {
        return "Hashtag must be alphanumeric and can only contain underscores.";
      }
      return true;
    },
    async uploadFunction(file) {
      return await this.uploadPostImage(file);
    },
    cancelPost() {
      window.location.href = `/publication/${this.publicationId}/posts`;
    },
    submitPost(title, content, status = "", onComplete = () => {}, force = false) {
      if (this.debounceEnabled || force) {
        if (this.post.status !== postStatus.ACTIVE) {
          this.updatePostIsLoading = false;
        }
        this.updatePublicationPost(this.post.post_id, title, content, status || this.post.status, onComplete);
      } else {
        this.debounceEnabled = true;
      }
    },
    updatePostExclusivityFlag() {
      this.updatePostExclusiveContentFlag(this.post.post_id, this.exclusive);
    },
  },
};
</script>

<style scoped lang="scss">
.native-post {
  &-title {
    width: 100%;
    padding-block: 10px;
    border: none;

    font-size: 26px;
    font-weight: 700;

    &:focus {
      outline: none;
    }
  }

  &-content {
    &-container {
      width: 100%;
      position: relative;

      .editor-container {
        width: 100%;

        :deep(.ProseMirror) {
          padding-block: 10px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    width: 100%;
  }
}

:deep(.tiptap) {
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>

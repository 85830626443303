<template>
  <PageWrapper
    :active-page="PAGE_CREATE_DIGITAL_PRODUCT"
    :active-section="SECTION_PUBLICATIONS"
    :breadcrumbs="breadcrumbs"
    align="left"
    page-name="Create Digital Product"
    title="Create Digital Product"
    width="full"
  >
    <iColumn :width="400" max-width-only>
      <iTextInput
        v-model="name"
        :required="true"
        label="Name"
        name="name"
        placeholder="Digital product name"
        type="text"
        width="fill"
      />
      <iTextInput
        v-model="description"
        :required="true"
        label="Description"
        name="description"
        placeholder="Best digital product..."
        type="text"
        width="fill"
        @blur="validateFormString"
      />
      <iColumn gap="extraSmall">
        <iLabel>
          Upload Digital Product
        </iLabel>
        <iUploader @upload-file="uploadDigitalProduct">
          <iRow vertical-align="middle">
            <iColumn width="hug">
              <iButton variant="secondary">
                Add File
              </iButton>
            </iColumn>
            <iText v-if="file" variant="subtle">
              {{ file.name }}
            </iText>
          </iRow>
        </iUploader>
      </iColumn>
      <iTextInput
        v-model="price"
        :padding-left-multiplier="2"
        :required="true"
        label="Price"
        name="price"
        placeholder="23.99"
        width="fill"
        @blur="validateIsPrice"
      >
        <template #prepend>
          {{ currencyCode.symbol }}
        </template>
      </iTextInput>
      <iButton
        :disabled="formHasError"
        :is-loading="isCreatingDigitalProduct"
        type="button"
        @click="submitForm"
      >
        Create Product
      </iButton>
      <iText v-if="formHasError" variant="error">
        {{ formError }}
      </iText>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { useDigitalProductsStore } from "@/stores/digital-products-store";
import { validateStringInput } from "@bloglovin/vue-component-library";
import { SECTION_PUBLICATIONS, PAGE_CREATE_DIGITAL_PRODUCT } from "@/constants/nav-constants";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";

export default {
  name: "CreateDigitalProduct",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_CREATE_DIGITAL_PRODUCT,
      formError: null,
      name: "",
      description: "",
      digitalProductUrl: "",
      price: 0,
      currencyCode: MAPPED_CURRENCY_CODES[0],
      startDateAvailable: 0,
      endDateAvailable: 0,
      file: null,
    };
  },
  computed: {
    ...mapState(useDigitalProductsStore, [
      "publicationId",
      "orgId",
      "isCreatingDigitalProduct",
    ]),
    ...mapState(usePublicationStore, ["publication"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
        {
          url: `/publication/${this.publicationId}/digital-products`,
          label: "Digital Products",
        },
      ];
    },
    formHasError() {
      return (
        this.formError !== "" &&
          this.formError !== null &&
          this.formError !== undefined
      );
    },
  },
  methods: {
    uploadDigitalProduct(e) {
      this.file = e;
    },
    ...mapActions(useDigitalProductsStore, {
      createDigitalProduct: "createDigitalProduct",
    }),
    validateFormString(e) {
      this.formError = validateStringInput(e.target.value);
    },
    validateIsPrice(e) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(e.target.value)) {
        this.formError = "Price must be a number";
      } else {
        this.formError = validateStringInput(e.target.value);
      }
    },
    submitForm() {
      if (this.formHasError) {
        return;
      }
      this.createDigitalProduct(
        this.name,
        this.description,
        this.digitalProductUrl,
        this.price,
        this.currencyCode,
        this.startDateAvailable,
        this.endDateAvailable,
        this.file,
      );
    },
  },
};
</script>

<style scoped></style>

<template>
  <iColumn>
    <iRow class="publication-header-wrapper" vertical-align="middle">
      <iOverlay
        :show-overlay-trigger="!canUploadLogo ? 'none' : isLoadingSplash ? 'always' :
          'hover' "
        class="splash-image"
        height="auto"
      >
        <iImageContainer
          :height="350"
          class="splash-image-container"
          :src="splashImage"
          aspect-ratio="4/17"
          object-fit="cover"
          width="fill"
        />
        <template #overlay>
          <iColumn
            align="center"
            height="fill"
            vertical-align="middle"
            width="fill"
          >
            <iLoadingSpinner v-if="isLoadingSplash" size="large" />
            <iColumn v-else width="hug">
              <iButton @click="$emit('click:splash-image')">
                Upload Splash
              </iButton>
            </iColumn>
          </iColumn>
        </template>
      </iOverlay>

      <iRow class="publication-header" vertical-align="middle">
        <iOverlay
          :show-overlay-trigger="!canUploadLogo ?'none' : isLoadingLogo ? 'always' : 'hover' "
          height="auto"
          width="auto"
        >
          <iImageContainer
            :height="$vssMobile ? 100 : 120"
            :src="logoImage"
            :width="$vssMobile ? 100 : 120"
            class="publication-header-logo"
            object-fit="cover"
            shape="circle"
          />
          <template #overlay>
            <iColumn
              align="center"
              height="fill"
              vertical-align="middle"
              width="fill"
            >
              <iLoadingSpinner v-if="isLoadingLogo" size="large" />
              <iColumn v-else width="hug">
                <iButton @click="$emit('click:logo-image')">
                  Upload
                </iButton>
              </iColumn>
            </iColumn>
          </template>
        </iOverlay>

        <iColumn gap="none" width="hug">
          <iSpace :height="10" />
          <iTextEdit
            v-if="canEditTitle"
            :value="title"
            show-as-text
            variant="locked"
            @submit="saveTitle"
          >
            <template #display>
              <iRow vertical-align="middle" wrap="nowrap" :width="500">
                <iSubHeading>{{ title }}</iSubHeading>
                <iColumn width="hug">
                  <iIcon icon="pencil" />
                </iColumn>
              </iRow>
            </template>
          </iTextEdit>
          <iRow v-else-if="title" :width="500">
            <iSubHeading>
              {{ title }}
            </iSubHeading>
          </iRow>
          <slot name="details">
            <iTextEdit
              v-if="canEditDetails"
              :value="details"
              show-as-text
              text-area
              :text-area-rows="3"
              :width="500"
              variant="locked"
              @submit="saveDetails"
            >
              <template #display>
                <iRow vertical-align="middle" wrap="nowrap" :width="500">
                  <iText variant="subtle">
                    {{ details }}
                  </iText>
                  <iColumn width="hug">
                    <iIcon icon="pencil" />
                  </iColumn>
                </iRow>
              </template>
            </iTextEdit>
            <iRow v-else-if="details" :width="500">
              <iText variant="subtle">
                {{ details }}
              </iText>
            </iRow>
          </slot>
        </iColumn>
      </iRow>
    </iRow>
    <iColumn class="main-content">
      <slot />
    </iColumn>
  </iColumn>
</template>

<script>
import { mapState } from "pinia";
import { useWindowStore } from "@bloglovin/vue-component-library";

export default {
  name: "ProfileWrapper",
  props: {
    splashImage: {
      type: String,
      required: true,
    },
    isLoadingSplash: {
      type: Boolean,
      required: false,
      default: false,
    },
    logoImage: {
      type: String,
      required: true,
    },
    isLoadingLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUploadSplash: {
      type: Boolean,
      required: false,
      default: false,
    },
    canUploadLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    details: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: [
    "click:splash-image",
    "click:logo-image",
    "click:save-title",
    "click:save-details",
  ],
  computed: {
    ...mapState(useWindowStore, [
      "$vssMobile",
    ]),
  },
  created() {
    useWindowStore().init();
  },
  beforeUnmount() {
    useWindowStore().remove();
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
  }),
  methods: {
    saveTitle(title) {
      this.$emit("click:save-title", title);
    },
    saveDetails(details) {
      this.$emit("click:save-details", details);
    },
  },
};
</script>

<style lang="scss" scoped>
.publication-header-wrapper {
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}

.main-content {
  position: relative;
  top: -40px;
}

.splash-image-container {
  height: 100%;
  aspect-ratio: 12/4;
}

.profile-image-container {
  height: 100%;
  aspect-ratio: 4/4;
}
</style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_NAVIGATION_CONFIG"
    title="Primary Navigation Menu"
    :breadcrumbs="breadcrumbs"
    page-name="Navigation"
    align="left"
    width="full"
  >
    <iColumn :width="600" align="right">
      <iRow width="hug">
        <iButton variant="secondary" @click="openAddMenu">
          Add Nav Item
        </iButton>
      </iRow>
      <iRow>
        <iSortable :model-value="primaryNavigation" @update:model-value="updatePrimaryNavigationConfig">
          <template #draggable-item="{menuItem, index}">
            <iRow
              align="between"
              class="menu-item-config"
              :width="500"
              gap="large"
            >
              <iColumn width="hug">
                <iText>{{ menuItem.label }}</iText>
              </iColumn>
              <iColumn width="hug">
                <iText>{{ menuItem.val }}</iText>
              </iColumn>
              <iColumn width="hug">
                <iDropdown :show-dropdown="isDropdownOpen(index)" position="left" @close="closeDropdownMenu()">
                  <template #trigger>
                    <iIcon icon="dots-vertical" @click="dropdownMenuItemIndex = index" />
                  </template>
                  <template #overlay>
                    <iColumn>
                      <iButton variant="tertiary" @click="openEditMenuForItemIndex(index)">
                        Edit
                      </iButton>
                      <iButton variant="tertiary" @click="removeMenuItem(index)">
                        Remove
                      </iButton>
                    </iColumn>
                  </template>
                </iDropdown>
              </iColumn>
            </iRow>
          </template>
        </iSortable>
      </iRow>
    </iColumn>
    <iModal
      :visible="isModalOpen"
      :title="`${isAddingMenuItem?'Add':'Edit'} Nav Item`"
      primary-action-button-label="Add Item"
      secondary-action-button-label="Cancel"
      :primary-action-button-disabled="!isValidNavItem"
      width="fill"
      @click:primary="saveMenuItem"
      @click:secondary="closeEditModal"
      @close="closeEditModal"
    >
      <template #body>
        <iColumn max-width-only :width="400">
          <iRow>
            <iTextInput v-model="updatingMenuItem.label" width="fill" placeholder="Display Text" />
          </iRow>
          <iSelect
            v-model="updatingMenuItem.type"
            return-value
            width="fill"
            :items="menuItemTypes"
            label="Menu Item Type"
            value-field="value"
          />
          <iRow>
            <iTextInput v-model="updatingMenuItem.val" width="fill" :placeholder="placeholderText" />
          </iRow>
          <iText v-if="updatingMenuItem.type==='url'" variant="subtle">
            Enter a url for this page, you will be able to edit that page later
          </iText>
          <iText v-if="updatingMenuItem.type==='hashtag'" variant="subtle">
            This landing page will show all posts tagged with the given hashtag
          </iText>
          <iText v-if="updatingMenuItem.type==='post'" variant="subtle">
            This is the post itself
          </iText>
        </iColumn>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import { useConfigurationStore } from "@/stores/configuration-store";
import { PAGE_PUBLICATION_NAVIGATION_CONFIG, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import PageWrapper from "@/components/PageWrapper";

export default {
  name: "PublicationNavigationConfig",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_NAVIGATION_CONFIG,
      SECTION_PUBLICATIONS,
      dropdownMenuItemIndex: -1,
      editMenuItemIndex: -1,
      isEditModal: false,
      isAddingMenuItem: false,
      updatingMenuItem: {
        label: "",
        val: "",
        type: "url",
      },
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    ...mapState(useConfigurationStore, ["primaryNavigation"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
    isModalOpen() {
      return this.isAddingMenuItem || this.editMenuItemIndex !== -1 ;
    },
    menuItemTypes() {
      return [
        { name: "static page", value: "url" },
        { name: "a post", value: "post" },
        { name: "a list of posts", value: "hashtag" },
      ];
    },
    placeholderText() {
      switch (this.updatingMenuItem.type) {
        case "url":
          return "/enter-url-here";
        case "hashtag":
          return "#hashtag";
        default:
          return "/";
      }
    },
    isValidNavItem() {
      switch (this.updatingMenuItem.type) {
        case "url":
          return this.isValidUrl(this.updatingMenuItem.val);
        case "hashtag":
          return this.isValidHashtag(this.updatingMenuItem.val);
        default:
          return true;
      }
    },
  },
  styleGuide: () => ({
    menuItemPaddingTop: { "size.spacing": "small" },
    menuItemPaddingBottom: { "size.spacing": "small" },
    menuItemPaddingLeft: { "size.spacing": "extraLarge" },
    menuItemPaddingRight: { "size.spacing": "small" },
  }),
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    isValidUrl(url) {
      const result = /^((\/)([a-zA-Z0-9]+(((\/|-)[a-zA-Z0-9]+)+)?)?)$/.test(url); // only relative urls
      if (!result) {
        return false;
      }
      return true;
    },
    isValidHashtag(hashtag) {
      const result = /^(#)?[a-zA-Z0-9_]+$/.test(hashtag);
      if (!result) {
        return false;
      }
      return true;
    },
    saveMenuItem() {
      let menuItems = [...this.primaryNavigation];
      if (this.isAddingMenuItem) {
        menuItems.push(this.updatingMenuItem);
      } else {
        menuItems[this.editMenuItemIndex] = this.updatingMenuItem;
      }
      this.updatePrimaryNavigationConfig(menuItems);
      this.closeEditModal();
    },
    updatePrimaryNavigationConfig(menuItems) {
      this.updateConfiguration({ primaryNav: menuItems });
    },
    isDropdownOpen(index) {
      return this.dropdownMenuItemIndex === index;
    },
    openEditMenuForItemIndex(menuItemIndex) {
      this.isAddingMenuItem = false;
      this.editMenuItemIndex = menuItemIndex;
      this.updatingMenuItem = { ...this.primaryNavigation[this.editMenuItemIndex] };
      this.closeDropdownMenu();
    },
    openAddMenu() {
      this.isAddingMenuItem = true;
      this.closeDropdownMenu();
    },
    closeEditModal() {
      this.editMenuItemIndex = -1;
      this.isAddingMenuItem = false;
      this.updatingMenuItem = {
        label: "",
        val: "",
        type: "url",
      };
    },
    removeMenuItem(menuItemIndex) {
      let menuItems = this.primaryNavigation.filter((menuItem, index) => index !== menuItemIndex);
      this.openDropdownIndex = -1;
      this.updatePrimaryNavigationConfig(menuItems);
      this.closeDropdownMenu();
    },
    closeDropdownMenu() {
      this.dropdownMenuItemIndex = -1;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.button-container){
  width: fit-content!important;
}
</style>
